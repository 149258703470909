/* navbar */
.navTabs {
    @apply py-2 px-3 md:p-0.5  hover:text-gray-400  text-gray-800 text-sm tracking-wide hover:border-b-2 hover:border-gray-300 font-medium
  }
  
  .activeTab {
    @apply border-b-2 border-gray-500 text-[--colorwhite]
  }


  
  .navbar-visible {
    position: fixed; /* Make the navbar fixed */
    top: 0; /* Stick to the top */
    z-index: 20; /* Ensure it is above other content */
    animation: down .7s linear;
    
  }

  @keyframes down {
    0%{
      opacity: 0;
        transform: translateY(-30px);
    }
    100%{
      opacity: 1;
        transform: translateY(0);
    }

  }
  
  