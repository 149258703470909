@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button,
    input[type='number'] {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield !important;
    }

 }

 input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1
}



.blur {
  filter: blur(5px);
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
}

.grayscale {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}


.blur-grayscale {
  filter: blur(5px) grayscale(1);
  -webkit-filter: blur(5px) grayscale(1);
  -moz-filter: blur(5px) grayscale(1);
  -o-filter: blur(5px) grayscale(1);
  -ms-filter: blur(5px) grayscale(1);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;

}



:root{



















  --maxWidth : 1920px;
  --colorBlack : #222831;
  --colorWhite: rgba(255,255,255,1);
  --mediumWhite: rgba(255,255,255,0.8);
  --colorGreen: #1A4D2E;
  --lightGreen: rgba(26, 77, 46, 0.05);
  --mediumGreen: rgba(26, 77, 46, 0.2);
  --darkGreen: rgba(15, 61, 34, 1);
  --lgWidth : 95%;
  --smWidth: 98%;

}



@media (prefers-color-scheme: dark) {
  :root {
    --colorBlack: #222831; /* Set colors suitable for light mode */
    --colorWhite: rgba(255, 255, 255, 1);
  }

  body {
    background-color: var(--colorWhite);
    color: var(--colorBlack);
  }

  .form-input {
    background-color: var(--lightGreen);
    color: var(--colorBlack);
  }

  /* Apply similar overrides for other components as necessary */
}



/* helpers */
.commonPadding{
  @apply md:px-8 md:py-10  px-4 py-8
}

.commonMargin {
  @apply my-8
}


.sectionWidth{
  @apply md:w-[95%] w-[98%] mx-auto 
}

.content{
  @apply w-[95%] mx-auto commonPadding
}

.textBig{
  @apply md:text-[46px] md:leading-[64px] text-3xl leading-[44px]  font-bold font-serif tracking-wider
}

.textXl{
  @apply text-xl tracking-wider
}

.textLg{
  @apply text-lg tracking-wide
}

.textBase{
  @apply text-base tracking-wide
}

.textSm {
  @apply text-sm
}


/* Button */

.primaryBtn{
  @apply bgGradient  w-full py-2 shadow-black/60 shadow-xl  rounded-full mt-10 text-white hover:border-white font-semibold tracking-wide
}

.secondaryBtn{
  @apply border md:px-16 py-2 rounded-full md:w-fit w-full backdrop-blur-sm hover:bg-green-600/15  transition-all duration-500 flex justify-center items-center tracking-wide
}




.bgGradient {
  background: linear-gradient(180deg,
   rgba(255,255,255,1) 0%,
    rgba(19,130,9, 0.5) 10%,
     rgb(3, 91, 20) 40%, 
     rgb(3, 28, 5) 95%,
      rgb(0, 20, 0) 100%);
      transition: all 0.7s ease;
}

.bgGradient:hover {
  background: linear-gradient(
  180deg,
   rgba(255,255,255,1) 0%,
    rgba(19,130,9, 0.5) 10%,
     rgb(12, 18, 13) 40%, 
     rgb(3, 28, 5) 95%,
      rgb(0, 20, 0) 100%)
}

.pageGradient {
  background: linear-gradient(180deg, rgb(85, 217, 104) 0%,rgba(47, 158, 50, 0.9) 5%, rgba(11, 116, 32, 0.9) 10%, rgb(2, 73, 16) 30%, rgb(3, 28, 5) 95%, rgb(0, 20, 0) 100%)}





.quote{
  @apply  md:text-lg  text-[--mediumWhite]  w-fit text-center
}


.form{
  @apply overflow-hidden relative !z-10 rounded-md w-full  text-[--colorWhite] shadow-inner shadow-white px-4 py-6 before:backdrop-blur-lg before:absolute before:inset-0 before:-z-10 before:rounded-md before:m-1
}
.form-input {
  @apply border border-gray-500 rounded-lg py-2 px-3 focus:outline-none focus:border-green-600  bg-[--lightGreen]
}

.tbl {
  @apply min-w-full
}

.tbl  tr > td {
  @apply md:px-3 py-2 px-1
}

.tbl thead {
  @apply border-b text-lg text-gray-600 uppercase
}



.tbl tr:nth-child(even){
  @apply bg-green-200  rounded-lg
}

.editBtn{
  @apply p-1.5 border border-green-500/40 hover:bg-green-600 bg-green-700/40 text-gray-800 rounded-full

}

.deleteBtn {
  @apply p-1.5 border bg-[--lightGreen] text-red-400  border-red-500/40 hover:bg-red-500/20 rounded-full
}

.updateBtn{
  @apply px-3 py-1 border bg-green-900 text-white rounded-lg
}
.cancleBtn{
  @apply px-3 py-1 border border-green-500/60 bg-[--lightGreen]  rounded-lg
}


.gallery {
  column-count: 4;
  --webkit-column-count: 4;
  --moz-column-count: 4;
  gap: 0.2rem;
  padding: 4rem;
}

.gallery > img {
  height: auto;
  width: 100%;
}



.imgContainer {
  margin: 0.3rem;
  overflow: hidden;
  border-radius: 1rem;
  border: green 1px solid;
  position: relative;
}


.dots span{
  @apply h-2 w-2 bg-green-600/50 
}

.imgContent {
@apply absolute inset-0 flex opacity-0  justify-center items-center z-10 bg-black/60 h-full w-full text-white text-lg 

}

.imgContent:hover .imgContainer > img{
  scale: 1.1;
  transition: all 0.5s ease;
  
}
.imgContainer:hover .imgContent {
  opacity: 1;
  transition: all 1s ease;
}
/* Responsive-ness for different screen-sizes */
@media screen and (max-width: 810px) {
  .gallery {
    column-count: 3;
    --webkit-column-count: 3;
    --moz-column-count: 3;
  }
}

@media screen and (max-width: 500px) {
  .gallery {
    column-count: 2;
    --webkit-column-count: 2;
    --moz-column-count: 2;
  }
}

@media screen and (max-width: 400px) {
  .gallery {
    column-count: 1;
    --webkit-column-count: 1;
    --moz-column-count: 1;
  }
}



